import { render, staticRenderFns } from "./addRecord.vue?vue&type=template&id=3fcaabca&scoped=true&"
import script from "./addRecord.vue?vue&type=script&lang=js&"
export * from "./addRecord.vue?vue&type=script&lang=js&"
import style0 from "./addRecord.vue?vue&type=style&index=0&id=3fcaabca&prod&lang=scss&"
import style1 from "./addRecord.vue?vue&type=style&index=1&id=3fcaabca&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fcaabca",
  null
  
)

export default component.exports